export const seo = {
  url: 'blog',
  title: {
    pl: 'Omida Blog | Nowości w TSL',
    en: 'Omida News | Latest TSL',
  },
  desc: {
    pl: 'Najnowsze informacje o firmie, rynku, produktach i wydarzeniach. Zyskaj wiedzę i bądź na bieżąco z informacjami od jednej z najlepszych spedycji w Polsce.',
    en: 'The latest information about the company, market, products and events. Gain knowledge and stay up to date with information from one of the best forwarders in Poland.',
  },
  keywords: ['omida', 'logistics', 'transport'],
}

export const intro = {
  title: {
    pl: 'Blog',
    en: 'News',
  },
  desc: {
    pl: 'Najnowsze informacje o firmie, rynku, produktach i wydarzeniach. Zyskaj wiedzę i bądź na bieżąco z informacjami od jednej z najlepszych spedycji w Polsce.',
    en: 'The latest information about the company, market, products and events. Gain knowledge and stay up to date with information from one of the best forwarders in Poland.',
  },
  button: {
    text: {
      pl: 'Przeglądaj artykuły',
      en: 'Browse articles',
    },
    action: 'SCROLL',
  },
}
