import axios from 'axios'
import React, { useState, useEffect, useCallback } from 'react'
import { useLangContext } from '../context/lang.context'
import { s, sInput, sInputError, sFormTextBottom } from '../style'
import { validateEmail } from '../utils'
import Button from './Button'
import { sContainer, sFormContainer } from './FormDynamic'
import FormFeedback from './FormFeedback'
import FormAgreements from './FormAgreements'
import Grid from './Grid'
import Headline from './Headline'
import { SectionBackground } from './Section'

const content = {
  title: {
    pl: 'Newsletter',
    en: 'Newsletter',
  },
  desc: {
    pl: 'Zapisz się i zyskaj więcej. Dołącz do subskrybentów newslettera Omida Logistics. Bądź na bieżąco z aktualnościami i ofertami TSL.',
    en: 'Subscribe and gain more. Join the subscribers of the Omida Logistics newsletter. Stay up to date with news and TSL offers.',
  },
  success: {
    title: { pl: 'Potwierdź subskrypcję!', en: 'Confirm the subscription!' },
    text: {
      pl: 'Jeszcze jeden krok! Na podany adres email przesłaliśmy email z potwierdzeniem zapisu do newslettera. Jeżeli nie widzisz wiadomości, sprawdź skrzynkę spam.',
      en: 'Just one more step! We have sent you an email with subscription confirmation. Cannot see the message? Check the spam folder.',
    },
  },
  failure: {
    title: { pl: 'Niepowodzenie!', en: 'Failure!' },
    text: {
      pl: 'Wygląda na to, że Twoja wiadomość nie została wysłana. Skontaktuj się z nami drogą mailową lub telefonicznie',
      en: 'It looks like your message has not been sent. Contact us by e-mail or call us',
    },
  },
  already: {
    title: { pl: 'Subskrybujesz!', en: 'Subscribing!' },
    text: {
      pl: 'Twój adres email znajduje się na liście subskrybentów newslettera Omida. Niebawem najnowsze informacje pojawią się na Twojej skrzynce pocztowej!',
      en: 'Your email address has already been added to the Omida newsletter subscribers. The newest posts will appear on your mailbox soon!',
    },
  },
  dictonary: {
    fill: {
      pl: 'Podaj email',
      en: 'Enter email',
    },
    send: {
      pl: 'Subskrybuj',
      en: 'Subscribe',
    },
    fillagreements: {
      pl: 'Zaznacz zgodę',
      en: 'Agree to the terms',
    },
  },
  agreements: [
    {
      pl: '* Wyrażam zgodę na otrzymywanie drogą elektroniczną informacji handlowych w rozumieniu ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną o treści pochodzących od Omida Logistics Sp. z o.o.',
      en: '* I agree to receive by e-mail commercial information coming from Omida Logistics Sp. z o.o. within the meaning of the Act of 18 July 2002 on electronic services',
    },
    {
      pl: 'Wyrażam zgodę na wykorzystywanie przez Omida Logistics Sp. z o.o. telekomunikacyjnych urządzeń końcowych i automatycznych systemów wywołujących celem przedstawienia informacji handlowych w rozumieniu przepisów ustawy z dnia 16 lipca 2014 r. - Prawo telekomunikacyjne',
      en: 'Ihereby consent to the use by Omida Logistics Sp. z o.o. of telecommunications terminal equipment and automated calling systems to present commercial information within the meaning of the Act of 16 July 2014. -Telecommunications Law',
    },
  ],
  administrating: {
    pl: 'Administratorem Państwa danych osobowych będzie Omida Logistics spółka z ograniczoną odpowiedzialnością (dalej: Omida). Można się z nami skontaktować w następujący sposób: listownie na adres: Aleja Grunwaldzka 472C, 80-309 Gdańsk, przez e-mail:',
    en: 'The administrator of your personal data will be Omida Logistics spółka z ograniczoną odpowiedzialnością (hereinafter: Omida). You can contact us as follows: by letter to: Aleja Grunwaldzka 472C, 80-309 Gdańsk, by e-mail:',
  },
  administratingSecond: {
    pl: ' lub telefonicznie: +48 58 741 88 14. Dane osobowe będziemy przetwarzać w celu przygotowania ofert dotyczących produktów i usług Omida. Szczegółowe informacje na temat przetwarzania danych osobowych znajdziesz',
    en: 'or by phone: +48 58 741 88 14. Personal data will be processed in order to prepare offers on Omida products and services. Details about the processing of your personal data are available',
  },
  administratingLinkText: {
    pl: 'tutaj',
    en: 'here',
  },
}

const FormNewsletter = () => {
  const { lang } = useLangContext()
  const [email, setEmail] = useState('')
  const [mailState, setMailState] = useState('unfilled')
  const [success, setSuccess] = useState(null)
  const [openBackdrop, setOpenBackdrop] = useState(false)
  const [wasSent, setWasSent] = useState(false)
  const initialFormAgreements = content.agreements.map((e) => false)
  const [formAgreements, setFormAgreements] = useState(initialFormAgreements)
  const agreementFilled = formAgreements[0]
  const [code, setCode] = useState(0)

  const validate = useCallback(() => {
    return validateEmail(email) && agreementFilled
  }, [email, agreementFilled])

  useEffect(() => {
    if (validate()) setMailState('filled')
    else setMailState('unfilled')
  }, [validate, setMailState])

  const onSend = (e) => {
    setSuccess(null)
    e.preventDefault()
    setWasSent(true)
    if (validate()) {
      setSuccess(null)
      setOpenBackdrop(true)
      setMailState('loading')
      axios({
        method: 'POST',
        url:
          process.env.NODE_ENV === 'development'
            ? 'http://localhost:5000/omida/newsletter/init'
            : 'https://cleverserver.appclever.pl/omida/newsletter/init',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        data: {
          email,
          campaignToken: 'jAAKH',
          lang,
        },
      })
        .then((res) => {
          setSuccess(true)
          setEmail('')
          setOpenBackdrop(true)
          setWasSent(false)
          setCode(res.data.code)
          setFormAgreements([false])
        })
        .catch((error) => {
          console.log(error)
          setOpenBackdrop(true)
          setSuccess(false)
        })
    }
  }

  return (
    <>
      <SectionBackground mobile top pbXL>
        <div css={sContainer}>
          <Headline
            medium
            gradient
            maxWidth
            title={content.title[lang]}
            desc={content.desc[lang]}
          />
          <form onSubmit={onSend}>
            <Grid
              container
              ccss={[
                sFormContainer,
                { marginBottom: '4rem', [s.sm_down]: { marginBottom: '1rem' } },
              ]}>
              <Grid item xs={12}>
                <input
                  type='email'
                  name='email'
                  placeholder='Email *'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  css={[
                    sInput,
                    wasSent && !validateEmail(email) && sInputError,
                    { marginBottom: 0, [s.md]: { marginBottom: 0 } },
                  ]}
                />
              </Grid>
            </Grid>
            <FormAgreements
              data={content.agreements}
              formAgreements={formAgreements}
              setFormAgreements={setFormAgreements}
              wasSent={wasSent}
            />
            <div css={sFormTextBottom}>
              <p>
                {content.administrating[lang]}{' '}
                <a href='mailto:bok@omida.pl'>bok@omida.pl</a>
                {content.administratingSecond[lang]}{' '}
                <strong>
                  <a
                    href={'/pdf/formularze.pdf'}
                    target='_blank'
                    rel='noreferrer'>
                    {content.administratingLinkText[lang]}
                  </a>
                </strong>
                .
              </p>
            </div>
            <Button
              noMargin
              center
              reactiveBlack
              active={wasSent ? validateEmail(email) : true}
              googleId={'send_button'}
              extraCss={{
                marginTop: '4rem',
                [s.sm_down]: { marginTop: '2rem' },
              }}
              action={onSend}>
              {wasSent && mailState !== 'filled'
                ? !agreementFilled
                  ? content.dictonary.fillagreements[lang]
                  : content.dictonary.fill[lang]
                : content.dictonary.send[lang]}
            </Button>
          </form>
        </div>
      </SectionBackground>
      {openBackdrop && (
        <FormFeedback
          success={success}
          open={openBackdrop}
          setOpen={setOpenBackdrop}
          dataSuccess={code === 2 ? content.already : content.success}
          dataFailure={content.failure}
        />
      )}
    </>
  )
}

export default FormNewsletter
